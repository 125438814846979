// Generated by Framer (005eb18)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["kIKoIFuiL"];

const variantClassNames = {kIKoIFuiL: "framer-v-1e9ifho"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, image, width, ...props}) => { return {...props, qjA75uDeC: image ?? props.qjA75uDeC ?? {src: new URL("assets/1024/fVHcOEKNUWW1czSUjcyLTAZY.jpg", import.meta.url).href, srcSet: `${new URL("assets/512/fVHcOEKNUWW1czSUjcyLTAZY.jpg", import.meta.url).href} 384w, ${new URL("assets/1024/fVHcOEKNUWW1czSUjcyLTAZY.jpg", import.meta.url).href} 768w, ${new URL("assets/2048/fVHcOEKNUWW1czSUjcyLTAZY.jpg", import.meta.url).href} 1536w, ${new URL("assets/fVHcOEKNUWW1czSUjcyLTAZY.jpg", import.meta.url).href} 3024w`}} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;image?: {src: string; srcSet?: string}; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, qjA75uDeC, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "kIKoIFuiL", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-7XvQg", classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-1e9ifho", className)} data-framer-name={"Default"} layoutDependency={layoutDependency} layoutId={"kIKoIFuiL"} ref={ref} style={{backgroundColor: "rgb(255, 255, 255)", ...style}}><Image background={{alt: "", fit: "fill", intrinsicHeight: 4032, intrinsicWidth: 3024, pixelHeight: 4032, pixelWidth: 3024, sizes: "445px", ...toResponsiveImage(qjA75uDeC)}} className={"framer-11jyh1c"} layoutDependency={layoutDependency} layoutId={"LnsyNSSu_"}/></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-7XvQg [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-7XvQg .framer-1qf7k1a { display: block; }", ".framer-7XvQg .framer-1e9ifho { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: 552px; justify-content: center; overflow: hidden; padding: 0px 0px 0px 0px; position: relative; width: 445px; }", ".framer-7XvQg .framer-11jyh1c { flex: none; height: 552px; overflow: hidden; position: relative; width: 445px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-7XvQg .framer-1e9ifho { gap: 0px; } .framer-7XvQg .framer-1e9ifho > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-7XvQg .framer-1e9ifho > :first-child { margin-left: 0px; } .framer-7XvQg .framer-1e9ifho > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 552
 * @framerIntrinsicWidth 445
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"qjA75uDeC":"image"}
 */
const FramerRXO13Ardw: React.ComponentType<Props> = withCSS(Component, css, "framer-7XvQg") as typeof Component;
export default FramerRXO13Ardw;

FramerRXO13Ardw.displayName = "Service - Carousel Image Container";

FramerRXO13Ardw.defaultProps = {height: 552, width: 445};

addPropertyControls(FramerRXO13Ardw, {qjA75uDeC: {__defaultAssetReference: "data:framer/asset-reference,fVHcOEKNUWW1czSUjcyLTAZY.jpg?originalFilename=photo-1568339434343-2a640a1a9946%3Fcrop%3Dentropy%26cs%3Dsrgb%26fm%3Djpg%26ixid%3DM3wxMzc5NjJ8MHwxfHNlYXJjaHw3fHxoYWlyY3V0fGVufDB8fHx8MTY5MjY5NzkxNHww%26ixlib%3Drb-4.0.jpg&preferredSize=auto", title: "Image", type: ControlType.ResponsiveImage}} as any)

addFonts(FramerRXO13Ardw, [])